.video-player-container {
  position: relative;
  width: 100%;
  height: 88vh;
  overflow: hidden;
  border-radius: 10px;
}

.video-player-container:hover .player-controls {
  opacity: 1;
  transform: translateY(0px);
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player video {
  object-fit: contain;
}

.player-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 38px;
  box-sizing: border-box;
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(200px);
}

.video-player-container button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.video-player-container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.video-player-container input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
  visibility: hidden;
}

.video-player-container input[type="range"]::-moz-range-track {
  height: 15px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
}

.video-player-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid #5b34fe;
  box-shadow: -407px 0 0 400px #5b34fe;
}

.video-player-container input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #5b34fe;
  box-shadow: -407px 0 0 400px #5b34fe;
}

.video-player-container span {
  color: white;
}

.volumeSliderContainer:hover .volumeSlider {
  transform: translateX(-50%) translateY(-110px) rotate(-90deg);
  opacity: 1;
}

.volumeSlider {
  height: 50px;
  width: 230px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%) translateY(200px) rotate(-90deg);
  transition: transform 0.3s, opacity 2s;
  opacity: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.volumeSlider input {
  width: 150px;
}

.volumeSlider span {
  transform: rotate(90deg);
}
