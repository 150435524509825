.signupContainer {
  display: flex;
  /* height: calc(100vh - 86px); */
  /* height: 110vh; */
  margin-top: 100px;
  gap: 20px;
}

.leftContainer {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightContainer {
  margin-top: -60px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.headingNormal {
  font-family: "CabinetGrotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 70px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.headingBold {
  font-family: "CabinetGrotesk";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  background: linear-gradient(180deg, #ce9ffc 0%, #a582f7 50.52%, #7367f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  margin-top: 10px;
}

.socialSignupBtns {
  margin-top: 25px;
  margin-bottom: 40px;
}

.signupWithGoogleBtn,
.signupWithMicrosoftBtn {
  background-color: #e9e9e9;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 463px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.signupWithMicrosoftBtn {
  margin-top: 24px;
}

.socialIcon {
  width: 30px;
  height: 30px;
}

.ORLabel {
  font-family: "CabinetGrotesk";
  font-weight: 800;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.customLoginlabel {
  font-family: "CabinetGrotesk";
  font-style: normal;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.inputContainer {
  margin-top: 50px;
}
.inputContainer2 {
  margin-top: 20px;
}

.inputField {
  width: 463px;
  height: 60px;
  background: #e9e9e9;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  font-family: "CabinetGrotesk";
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: -0.02em;
}

.appButton {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #ce9ffc 0%, #a582f7 50.52%, #7367f0 100%);
  box-shadow: 10px 20px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 466px;
  height: 62px;
  font-family: "CabinetGrotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signupRightBGContainer {
  position: absolute;
  right: 0;
  z-index: 99;
}

.signupFGContainer {
  position: absolute;
  right: 80px;
  z-index: 99;
}

.forgotPasswordContainer {
  width: 63%;
  position: relative;
  z-index: 999;
}

.forgotPassword {
  font-family: "CabinetGrotesk";
  width: 100%;
  margin-top: 15px;
  text-align: right;
  color: rgb(131, 130, 130);
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.currentUserCard {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardMain {
  background-color: rgba(197, 197, 197, 0.495);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 30px 70px;
  border-radius: 10px;
}

.profilePic {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.userName {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 2px;
}

.userEmail {
  font-size: 14px;
}

.logout {
  margin-top: 5px;
  color: #7367f0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
