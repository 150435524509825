@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import "./cabinet-grotesk.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "CabinetGrotesk-Bold";
}

.gradient {
  background: linear-gradient(180deg, #ce9ffc 0%, #a582f7 50.52%, #7367f0 100%);
  box-shadow: 10px 20px 20px rgba(0, 0, 0, 0.05);
}
.setOverflow {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.setOverflow::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}

.font-inter-400 {
  font-family: "Inter" !important;
  font-weight: 400 !important;
}

.font-inter-500 {
  font-family: "Inter" !important;
  font-weight: 500 !important;
}

.font-inter-700 {
  font-family: "Inter" !important;
  font-weight: 700 !important;
}

.font-cabinet-400 {
  font-family: "CabinetGrotesk-Regular" !important;
  font-weight: 400 !important;
}

.font-cabinet-500 {
  font-family: "CabinetGrotesk-Medium" !important;
  font-weight: 500 !important;
}

.font-cabinet-700 {
  font-family: "CabinetGrotesk-Bold" !important;
  font-weight: 700 !important;
}

.__floater__arrow {
  display: none !important;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 200px;
}

.thumbnail-player {
  position: absolute;
  top: 0;
  left: 0;
}
