.headerContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 13vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.101);
}

.headerLogo {
  width: 50px;
  height: 50px;
}
